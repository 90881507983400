
.tagsListContainer {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  align-items: flex-end;
  align-content: flex-end;

}


.tagsListContainer.rightAlign{
  /* margin-right: 20px; */
  /* max-width: 90%;
  width: 90%; */
  padding: 0;
  margin: 0;

  /* display: none; */
  overflow: none;
  flex-shrink: 1;
  max-width: calc(var(--max-app-width) - 300px);
  width: auto;
  margin-left: auto;

  /* box-sizing: content-box; */
  /* display: none; */
  /* overflow: hidden; */

}

.tagsListContainer.isSidebar{
  min-height: 100%;
  height: 100%;
  flex-wrap: wrap;
  z-index: 100000000000000;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5px;
  /* padding-bottom: 20px; */
  overflow: visible;
  padding-top: 10px;
  /* width: 100%; */
  /* margin-left: 0;
  margin-right: auto; */
}

.tagsListContainer.isSidebar div.tagContainer{
  cursor: pointer;
}


