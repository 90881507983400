
#sideBarContainer {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  


  box-sizing: border-box;
  overflow: visible;
  display: flex;
  position: relative;
  z-index: 0;

  /* backface-visibility: hidden; */

  width: var(--sidebar-width);
  max-width: var(--sidebar-width);
  min-width: var(--sidebar-width);
  height: '100vh';
  min-height: 100%;
  max-height: 100%;
  padding-top: calc(var(--top-header-height) + var(--navContainer-height) + 20px);

  z-index: 99;
}



#sideBarContainer.clickedSmallScreenOverlay{
  position: absolute;
  width: var(--sidebar-width);
  max-width: var(--sidebar-width);
  min-width: var(--sidebar-width);
  right: 0;
  top: var(--top-header-height);
}




#sideBarContentContainer {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  /* flex-basis: auto; */
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  margin-top: calc(-1 * var(--top-header-height));
  height: '100vh';
  min-height: 100%;
  max-height: 100%;
  z-index: 0;
  position: fixed;
  max-width: var(--sidebar-width); 
  padding: 10px;
  /* background-color: rgba(200,200,200,0.3) */

  background-color: var(--nav-bg-semi);
}



/* #sideBarContentItemsContainer { */
  /* -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: center;
  
  box-sizing: border-box;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;

  position: relative;
  z-index: 0;
  display: flex;
    padding-left: 400px; */

  /* height: 100%;
  min-height: 100%; 
 } */


#sideBarContentItems {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;

  position: relative;
  z-index: 0;
  /* padding-bottom: 64px; */
  /* padding-top: 12px; */
  
}



.sideBarContentItemContainer {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;

  padding-top: 10px;
  position: relative;
  z-index: 0;
  border-style: solid;
  border-width: 0px;
  border-color: rgb(47, 51, 54);
  background-color: transparent;
  margin-bottom: 10px;

  overflow: visible;
  
}



.sideBarContentItem {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  /* -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
   */
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  
  position: relative;
  z-index: 0;
  
}



.sideBarContentItemHeader {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0;
  /* min-height: 0px; */
  min-width: 0px;
  position: relative;
  z-index: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-top: 12px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: #fff;
}



.sideBarContentItemHeader h2 {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0);
  color: inherit;
  font: inherit;
  list-style: none;
  text-align: inherit;
  text-decoration: none;
  -webkit-box-align: stretch;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  margin: 0;
  /* min-height: 0px; */
  min-width: 0px;
  position: relative;
  z-index: 0;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  flex-direction: row;
}



.sideBarContentItemHeader h2 span {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  pointer-events: auto;
  list-style: none;
  text-align: inherit;
  -webkit-box-direction: normal;
  
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  color: inherit;
  font: inherit;
  white-space: inherit;
  min-width: 0px;
  overflow-wrap: break-word;
  font-family: inherit;
  text-align: center;
}



.sideBarContentItemBodyContainer {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column; 
}



.sideBarContentItemBodyInner {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;

  height: 100%;
  min-height: 100%;

  position: relative;
  z-index: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}



#footerContainer {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 10px;
  text-align: right;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: flex-end;
  font-family: 'Oswald', 'Montserrat Alternates', 'Montserrat', sans-serif;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  overflow: visible;
  border-style: solid;
  border-color: rgb(47, 51, 54);
  border-width: 0px;
  /* margin-bottom: -106px; */
  position: absolute;
  margin-top: auto;
  bottom: 0px;
  border-radius: 0px;
  color: #fff;
  font-weight: normal;
  padding: 4px 3px;
  z-index: 5033333333330000;
  right: 0;
  align-items: flex-end;
}



#footerContainer span{
  position: relative;
  background-color: rgba(50,50,50,0.2);
  padding: 1px;
  display: block;
  margin-top: 1px;
  width: min-content;
  word-wrap:unset;
  word-break: keep-all;
  white-space: nowrap;
  overflow: visible;
  margin-right: 0;
  /* margin-left: auto; */
}



#footerContainer span a{
  color: #fff;
  text-decoration: none;

}
