.quoteEntry{

    height: 150px;
    background-color: navy;
}

.postButton{
    background-color: var(--nav-selected-bg);
    width: 120px;
    content: 'Post'
}

.feedItem.quoteEntry .toolBtnOuter{
    /* right: 30px;
    position: absolute; */
    position: absolute;
    bottom: -5px;
    right: 0px;
}

.feedItem.quoteEntry .toolBtnOuter .toolBtn{
    top: -120px;
    width: 130px;
    color: #000;
    font-size: 16px;
    font-weight: normal;
    padding-top: 0px;

}

.feedItem.quoteEntry .toolBtnOuter .toolBtn span{
    line-height: 18px;    
    font-family:'Oswald','Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.privacyCheckbox{
    
}

div.quoteEntryOpts{
    position: relative;
    min-width: 100%;
    width: 100%;
    /* right: 130px; */
    min-height: 100%;
    height: 100%;
    z-index: 99999999;
    /* margin-bottom: 0px; */
}