:root{
  --chamferedBox-bg: #888;
  --chamferedBox-bg:rgb(40, 60, 136);
  --chamferedBox-bg:rgb(40, 60, 136,0.8);
  --chamferedBox-bg:rgb(40, 60, 136,1);

  --chamferedBox-bg-grayScale: var(--theQuote-bg);

}

/* 
.chamferedBox{ 
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  position:absolute;
  left:0.25em;
  left:0;
  top:0em;
  width:var(--chamferedBox-w);

  display: block;
  height: 38px; 
  padding: 0px; 
  -webkit-transform: rotate(0deg) skew(0deg); 
  transform: rotate(0deg) skew(0deg); 
  border-left: 41px solid var(--chamferedBox-bg) 
  border-right: 41px solid var(--chamferedBox-bg)
  border-bottom:0px solid transparent; 
  border-top:10px solid transparent; 

} */


.chamferedBox{
 

  background:  var(--chamferedBox-bg);
  background:
      -moz-linear-gradient(45deg,  var(--chamferedBox-bg)  10px, var(--chamferedBox-bg) 10px),
      -moz-linear-gradient(135deg, transparent 10px, var(--chamferedBox-bg) 10px),
      -moz-linear-gradient(225deg, var(--chamferedBox-bg)  10px, var(--chamferedBox-bg) 10px),
      -moz-linear-gradient(315deg, transparent 10px, var(--chamferedBox-bg) 10px);

  background:
      -o-linear-gradient(45deg,  var(--chamferedBox-bg)  10px, var(--chamferedBox-bg) 10px),
      -o-linear-gradient(135deg, transparent 10px, var(--chamferedBox-bg) 10px),
      -o-linear-gradient(225deg, var(--chamferedBox-bg)  10px, var(--chamferedBox-bg) 10px),
      -o-linear-gradient(315deg, transparent 10px, var(--chamferedBox-bg) 10px);

  background:
      -webkit-linear-gradient(45deg,  var(--chamferedBox-bg) 10px, var(--chamferedBox-bg) 10px),
      -webkit-linear-gradient(135deg, transparent 10px, var(--chamferedBox-bg) 10px),
      -webkit-linear-gradient(225deg, var(--chamferedBox-bg) 10px, var(--chamferedBox-bg) 10px),
      -webkit-linear-gradient(315deg, transparent 10px, var(--chamferedBox-bg) 10px); 

  background-position: bottom left, bottom right, top right, top left;
  -moz-background-size: 50% 50%;
  -webkit-background-size: 50% 50%;
  background-size: 50% 50%;
  background-repeat: no-repeat;
}


.chamferedBox.grayScale{
  /* background:  var(--chamferedBox-bg-grayScale); */
  /* background:
      -moz-linear-gradient(45deg,  var(--chamferedBox-bg-grayScale)  10px, var(--chamferedBox-bg-grayScale) 10px),
      -moz-linear-gradient(135deg, transparent 10px, var(--chamferedBox-bg-grayScale) 10px),
      -moz-linear-gradient(225deg, var(--chamferedBox-bg-grayScale)  10px, var(--chamferedBox-bg-grayScale) 10px),
      -moz-linear-gradient(315deg, transparent 10px, var(--chamferedBox-bg-grayScale) 10px);

  background:
      -o-linear-gradient(45deg,  var(--chamferedBox-bg-grayScale)  10px, var(--chamferedBox-bg-grayScale) 10px),
      -o-linear-gradient(135deg, transparent 10px, var(--chamferedBox-bg-grayScale) 10px),
      -o-linear-gradient(225deg, var(--chamferedBox-bg-grayScale)  10px, var(--chamferedBox-bg-grayScale) 10px),
      -o-linear-gradient(315deg, transparent 10px, var(--chamferedBox-bg-grayScale) 10px);
  background:
      -webkit-linear-gradient(45deg,  var(--chamferedBox-bg-grayScale) 10px, var(--chamferedBox-bg-grayScale) 10px),
      -webkit-linear-gradient(135deg, transparent 10px, var(--chamferedBox-bg-grayScale) 10px),
      -webkit-linear-gradient(225deg, var(--chamferedBox-bg-grayScale) 10px, var(--chamferedBox-bg-grayScale) 10px),
      -webkit-linear-gradient(315deg, var(--chamferedBox-bg-grayScale) 10px, var(--chamferedBox-bg-grayScale) 10px);

      background-position: bottom left, bottom right, top right, top left;
      -moz-background-size: 50% 50%;
      -webkit-background-size: 50% 50%;
      background-size: 50% 50%;
      background-repeat: no-repeat; */

}
