:root{
  --nav-selected-bg: rgb(255, 196, 0);
}

.navButtonOuter {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  z-index: 0;
  padding-left: 0px;
  padding-right: 0px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  scroll-snap-align: start;
  cursor:pointer;
}

.navButton{
  background-color: none;
  border: none;
  border-bottom: solid 2px transparent;
}

.navButtonOuter:hover, .navButtonOuter.active {
  background-color: rgba(255, 229, 143);
}

.navButtonOuter.active {
  background-color: var(--nav-selected-bg);
  border-bottom: solid 2px #000;

  border-bottom: solid 2px var(--header-bg);
}





button.navButton {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  pointer-events: auto;
  list-style: none;
  text-align: inherit;
  cursor: pointer;
  unicode-bidi: isolate;
  
  box-sizing: border-box;

  padding: 0;
  margin: 0;
  white-space: pre-wrap;
  display: flex;
  position: relative;
  align-items: center;
  align-self: center;
  align-content: center;

  min-width: 0px;
  line-height: 18px;
  height: 53px;

  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  flex-direction: row;
  background-color: transparent;
  margin-top: auto;
  margin-bottom: auto;

}

.navButton span {
  background-color: none;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  pointer-events: auto;
  list-style: none;
  text-align: inherit;
  cursor: pointer;
  -webkit-box-direction: normal;
  
  box-sizing: border-box;
  display: inline;
  color: inherit;
  font-family:'Oswald','Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 400;
  font-size: 18px;

  white-space: inherit;
  min-width: 0px;
  text-align: center;
  color: #000;

  
  white-space: pre-wrap;
  width: min-intrinsic;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  height: auto;
  display: table-caption;
  display: -ms-grid;
  -ms-grid-columns: min-content;
  user-select: none;
}

div.navButtonOuter.searchNavItem, div.navButtonOuter.searchNavItem.active{
  width: 0;

  z-index: -10000;
  flex-grow: 0.9;
  overflow: hidden;
}

div.navButtonOuter.searchNavItem span{
  display: none;
  margin: 0;
}


div.navButtonOuter.searchNavItem button.navButton{
  position: relative;
  margin: 0px -20px;
  padding: 0;
  transform: scale(0.6);
}

div.navButtonOuter.searchNavItem button.navButton:before{
  box-shadow: 0 0 0 0.2em #000 inset;
  padding: 0;
  margin: 0;
  margin-top: 4px;

}

div.navButtonOuter.searchNavItem button.navButton:after{
  background: #000;
  margin-top: 4px;
}