.theQuoteAndRibbonContainer{
  display: flex;
  flex-idrection: row;
}


/* common */
.ribbon {
  width: 165px;
  height: 120px;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  
}

.ribbon span {
  position: absolute;
  display: block;
  width: 390px;
  height: 20px;
  padding: 4px 0px 0px 35px;
  /* margin-top: 10px; */
  /* box-shadow: 0 2px 5px rgba(0,0,0,.1); */
  color: #fff;
  color: #000;
  font: 700 8px/1 'Lato', sans-serif;
  /* text-shadow: 1px 1px 1px rgba(0,0,0,1); */
  text-transform: uppercase;
  text-align: center;
  white-space: break-spaces;
  overflow-wrap: break-word;
  overflow-wrap: anywhere;

  
}


.ribbonContainer{
  position: relative;
  right: -60px;
  margin-top: 0px;

  /* overflow: hidden; */


  /* right: 40px; */
  /* right: 9%; */
  /* left: 64%;
  top: 23px; */
}


.ribbonContainer .ribbon span{
  background-color: var(--nav-selected-bg);
}

.ribbonContainer.recentlyTweeted .ribbon span{
  background-color: var(--header-bg);
  color: #fff;
}

/* top right*/
.ribbon-top-right {
  margin-top: 0px;
  right: 0px;
  overflow: hidden;
  /* right: -104px; */
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -100px;
  top: 7px;
  transform: rotate(35deg);
}













/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}






/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}







/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}