:root{
  --unchecked-star: '\2606';
  --unchecked-star: 'any';

  --checked-star: '\2605';
  --thumbs-down: '\1F44E';
  --star-filled-color: rgb(120,120,120,1);
  /* --user-star-filled-color: var(--nav-selected-bg); */
  --user-star-filled-color: rgba(241,140,10,1);

  /* --avg-star-filled-color: rgb(0,0,0); */
  --avg-star-filled-color: rgb(0,0,0,0.2);


  --star-color: rgba(230,215,200,1);
}



.ratingsCont{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}



.nRatingsDiv{
  width: auto;
  border-radius: 6px;
  font-size: 10px;
  line-height: 15px;
  color: white;
  position: absolute;

  right: 112px;
  margin-top: -2px;
  font-weight: bold;
  padding: 0px 4px;
  background: var(--avg-star-filled-color);
}



.ratingsOuter {
  font-size: 25px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  scroll-snap-align: start;
  cursor:pointer;
  position: absolute;

  display: flex;
  flex-direction: row;
  border-bottom-right-radius: 5px;
  margin: 0;
  padding: 14px 0 22px;
   
  opacity: 0.8;
  color: #333;
  --user-percent: calc(var(--user-rating) / 5 * 100%);
  --avg-percent: calc(var(--avg-rating) / 5 * 100%);
}



.ratingsOuter::before{
  font-family: 'Noto Sans Symbols 2', sans-serif;
  margin-left: auto;
  content: '👎';
  margin-top: 4px;
  margin-right: 5px;
  opacity: 0.2;
  font-size: 18px;
}



.ratingsOuter.thumbAbsent::before{
  opacity: 0;
}

.ratingsOuter.thumbFilled::before{
  opacity: 1;
  background: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: black;
  /* text-fill: #fff; */
  text-shadow: 0 0 3px #000;
}

.ratingsOuter::after {
    content: '★★★★★';
    background: linear-gradient(90deg, var(--user-star-filled-color) var(--user-percent), var(--star-color) var(--user-percent) );

    /* background: linear-gradient(90deg, var(--star-filled-color) var(--percent), var(--star-color) var(--percent) ),
      linear-gradient(0.5turn, yellow 0% 43%, green 43% 100%);
      background: linear-gradient(90deg, var(--star-filled-color) var(--percent), var(--star-color) var(--percent) ),
      linear-gradient(0.5turn, yellow 0% 43%, green 43% 100%); */
    -webkit-background-clip: text;
    background-clip: text;

    -webkit-text-fill-color: transparent;
    letter-spacing: -4px;
    padding-right: 4px;
}

.ratingsOuter.avgRatings{
  padding-top: 6px;
  right: 7px;
 }

.ratingsOuter.avgRatings::before {
  background: linear-gradient(90deg, var(--avg-star-filled-color) var(--avg-percent), transparent var(--avg-percent) );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -4px;
  padding-right: 4px;
  height: 15px;
  opacity: 0;
  margin-left: auto;

}

.ratingsOuter.avgRatings::after {
  content: '★★★★★';
  background: linear-gradient(90deg, var(--avg-star-filled-color) var(--avg-percent), transparent var(--avg-percent) );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -4px;
  padding-right: 4px;
  height: 18px;
}


.ratingsInner{
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.ratingsValOuter{
  width: min-content;
  padding: 0;
  margin: 0;
  width: 10px;
  height: 100%;
  z-index: 10000 !important;
  background-color: black;
}

fieldset{
  padding: 0;
  margin: 0;
  border: none;

}

.ratingsOuter .thumbDown{
  content: var(--thumbs-down);
}


.ratingsOuter .ratingValOuter span.star{
  letter-spacing: 0;
  display: inline-block;
  overflow: hidden;
  width: min-content;
  margin-left: -2px;
}

/* --percent: calc(var(--rating) / 5 * 100%); */


.ratingsOuter .star .filled{
  content: var(--checked-star);
}

.ratingsOuter input{
  display: none;
}


.thumbs {
  font-family: 'Noto Sans Symbols 2', sans-serif;
  margin-right: 10px;
  font-size: 18px;
  opacity: 0.4;
}
