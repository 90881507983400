span.quoteSpansOuter{
  cursor: text;
  min-width: 100%;
  width: 100%;
  user-select: text;
	-webkit-user-select: text;
	-moz-user-select: text;

}

.quoteSpan{
  cursor: text;
  
}

.quoteSpan.startSpan{
  padding: 4px 0px;
  line-height: 22px;

}

span.invertedComma{
  user-select: none;
}

span.invertedComma.startInvertedComma{
  margin-left: 50px;

}

.essenceSpan{
  color: #fff;
  /* font-weight: bold; */
  background-color: var(--essence-hl);
  padding: 4px 4px;
}

.quoteSpansOuter span.ensureMaxWidth{
  background-color: blue;
  background-color: transparent;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre;
}
