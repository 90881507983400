:root {
  --logo-shadow: rgb(255, 196, 0);


  --app-width: 100%;
  --max-app-width: 988px;

  /* --max-sidebar-width: 118px; */
  /* --sidebar-width: 16%; */
  --sidebar-width: 110px;
  --sidebar-shrunk-width: 7px;

  --header-bg: rgb(60, 60, 136);
  --top-header-height: 35px;
  --header-container-height: 88px;

  --tag-list-border-col: rgb(0, 100, 150);
  --tag-list-border-col: rgb(60, 60, 136);
  --tag-list-border-col: rgb(60, 60, 136);

  /* --tag-list-border-bg: rgb(255,255,255); */


  --body-bg: lightyellow;
  --body-bg: #dfdfdf;
  --body-bg: #efefef;
  --body-bg: #707070;

  --body-bg: #d0d0d0;
  --body-bg: #e0e0e0;
  --body-bg: #f0f0e9;


  --feedItemInner-bg: rgb(235, 235, 216,1);
  --feedItemInner-bg: rgba(0,0,100,0.02);

  --theQuote-bg: rgba(220,220,225,0.8);


  --theFeedContainer-bg: transparent;

  --essence-hl: rgb(50,50,50);
  --essence-hl: rgb(0,0,0);

  --content-feed-max-width-shrunk: calc(var(--max-app-width) - var(--sidebar-shrunk-width));
  --content-feed-max-width: calc(var(--max-app-width) - var(--sidebar-width));

  --feedItemInner-max-width-shrunk: calc(var(--content-feed-max-width-shrunk) - 264px);
  --feedItemInner-max-width: calc(var(--content-feed-max-width) - 264px);
  
  /* --feedItemInner-max-width-shrunk: calc(var(--content-feed-max-width-shrunk) - 40%);
  --feedItemInner-max-width: calc(var(--content-feed-max-width) - 40%); */

}




::-webkit-scrollbar {
  -ms-overflow-style: none;  
  scrollbar-width: none;  

  /* width: 0;  */
  /* height: 0; */
  /* background: transparent; */
  /* background: transparent;  Optional: just make scrollbar invisible */
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  /* min-height: 100%;
  overflow-y: scroll; 
  height: 100%;  */
  overscroll-behavior-y: none; 
  font-size: 15px;

  background-color: var(--body-bg);

}


#appContainer{
  height: 100%;
  overflow: hidden;
  min-width: 100%;
  width: 100%;
  /* max-width: var(--max-app-width); */
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: 100%;
  touch-action: manipulation;

}

#contentContainer{
  min-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;

  flex-direction: column;
}



@media (max-width: 730px) {


  div.quoteTagsContainer{
    display: none;
  }

  div#sideBarContainer {

    width: var(--sidebar-shrunk-width);
    max-width: var(--sidebar-shrunk-width);
    min-width: var(--sidebar-shrunk-width);
    border: solid 2px var(--tag-list-bg-col);

  }

  div#sideBarContentContainer{
    background: var(--tag-list-border-col);
    border-color: var(--tag-list-border-col);
  }

  .clickedSmallScreenOverlay div#sideBarContentContainer{
    background: transparent;
    background: var(--body-bg);
  }
  

  .searchBar input{
    max-width: var(--feedItemInner-max-width-shrunk);

  }

  .searchBar{
    max-width: var(--feedItemInner-max-width-shrunk);

  }

  .fillerSpan{
    max-width: var(--feedItemInner-max-width-shrunk);

  }


  /* div.sideBarSpacer{
    display: none;
  } */

  div#footerContainer{
    display: none;
  }

  .feedItem{
  
    max-width: var(--content-feed-max-width-shrunk);

  }

  div#theFeedContainer{
    max-width: var(--content-feed-max-width-shrunk);
    /* max-width:var(--content-feed-width-nosb); */
    /* width: 100%;
    min-width: 100%; */
  }

  
  
}



#bodyOuter {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  position: relative;
  z-index: 0;
  width: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  -webkit-box-align: stretch;
  align-items: center;
  align-content: center;
  background-color: lightblue;
  height:100%;
  display:flex;

}



#bodyInner {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  margin:0;
  
  position: relative;
  z-index: 0;
  -webkit-box-flex: 1;
 
  width:100%;
}



#theFeedAndSidebar{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-width: 100%;
  width: 100%;
  max-width: var(--app-width);
  margin: 0;
  /* min-height: 100%;
  height: 100%;
  overflow: auto; */
}


.contentFeedOuter {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;

  position: relative;
  border-style: solid;
  border-color: rgb(47, 51, 54);
  z-index: 1;
}

.contentFeedInner {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  z-index: 0;
  min-width: 100%;
  width: 100%;

  min-height: 100%;
  height: 100%;
}



#theFeedContainer {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: center;
  
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  min-height: 100%;
  height: 100%;
  /* min-width: 100%; */
  min-width: 80%;
  width: 100%;
  max-width: var(--content-feed-max-width);
  background-color: var(--theFeedContainer-bg);
}



#theFeedContents {
  display: flex;
  min-height: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-direction: normal;
  position: relative;
  background-color: none;
  box-sizing: content-box;
  min-width: 100%;
  flex-grow: 1;
  width: 100%;
  margin-top: 88px;
  padding-bottom: 88px;
  /* margin-top: 174px; */

}



.contentFeedFeedItemsOuter {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin:0px;
  min-height: 0px;
  min-width: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  position: relative;
  z-index: 0;
  display: block;
  outline-style: none;
  background-color: var(--theFeedContainer-bg);

}



.contentFeedFeedItemsInner {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin:0;
  min-height: 0px;
  min-width: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  position: relative;
  z-index: 0;
}



.contentFeedFeedItemContainer {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  align-items: stretch;
  
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  margin: 0;
  min-height: 0px;
  min-width: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  position: relative;
  z-index: 0;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  outline-style: none;
  padding-left: 16px;
  padding-right: 16px;

  height: 200px;
}


