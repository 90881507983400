#infiniteScrollContainer{
  min-height: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  cursor: 'grab';
  max-width: 100%;
  /* min-width: 100%; */
  /* width: 100%; */
}


#feedItemsCont{
 
  display: flex;
  flex-direction: column;
  overflow: visible;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  /* max-width: 100%; */
  max-width: var(--content-feed-max-width);

}
/* 
#feedItemsCont.Yours, #feedItemsCont.Search{
  min-width: 100%;
  width: 100%;


} */



