form{
  margin: 0;
  padding: 0;
}
button, input {
	font: 1em Hind, sans-serif;
	line-height: 1.1em;
}
input {
	/* color: #171717; */
	webkit-user-select: auto; 
	webkit-user-select: text;
}
.searchBar {
	display: flex;
}
.searchBar input,
.searchBtn, 
.searchBtn:before, 
.searchBtn:after {
	transition: all 0.25s ease-out;
}

.searchBar input:invalid:not(:focus),
.searchBtn {
	cursor: pointer;
}



.searchBar input:focus,
.searchBar input:not(:focus) + .searchBtn:focus {
	outline: transparent;
}



.searchBar {
	margin: 0;
  padding: 0;

	background-color: var(--header-bg);
	display: flex;
	width: 100%;
	min-width: 100%;
	max-width: var(--feedItemInner-max-width);
	/* max-width: calc(var(--content-feed-max-width) + 20px); */

	/* max-width: 70%; */

}



.searchBar input {
	display: flex;
  background: rgba(255,255,255,0.3);
	border-radius: 5em;
  box-shadow: none;
  outline: none;
  border: none;

	padding: 0.75em;
  padding: 0 0.75em;

	font-size: 14px;
  color: #fff;

	width: 100%;
	min-width: 100%;
	max-width: var(--feedItemInner-max-width);

  height: 26px;
  margin: 5px;
	margin-left: 3px;
	margin-bottom: 4px;
	margin-right: 0px;
	padding-left: 32px;

  position: relative;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.searchBarContainerDiv{
	display: flex;
	flex-direction: column;
	min-width: 100%;
	width: 100%;
	max-width: var(--feedItemInner-max-width);

	background-color: blue;
	
}



.searchBar input::-webkit-search-decoration {
	-webkit-appearance: none;
}



/* input[type="search"]::-webkit-search-cancel-button{
	position:relative;
	right:20px;    
	color: #fff;

	-webkit-appearance: none;
	height: 20px;
	width: 20px;
	content: 'x'
	
} */

.searchBtn {
	width: 3em;
	height: 3em;
}

/* To distinguish between search btn in nav and searchbtn in bar */
.searchBar .searchBtn{
	margin-top: -3px;
	margin-left: 0px;
	width: 2.5em;
	height: 2.5em;
}
.searchBar .searchBtn:before{
	width: 1em;
	height: 1em;
}

.searchBar  .searchBtn:after{
	left: 49%;
	top: 60%;
}




/* all other search btns*/
.searchBtn {

	border-radius: 0 0.75em 0.75em 0 / 0 1.5em 1.5em 0;
	padding: 0.75em;

	
	position: absolute;
	margin-top: -1px;
	margin-left: 3px;


	border: none;
	transform: scale(0.8);
	/* transform: translateY(1px); */
	background: transparent;

}

.searchBtn:before, 
.searchBtn:after {
	content: "";
	display: block;
	/* opacity: 0; */
	position: absolute;
  background: transparent;
  transform: translateY(1px);
}
.searchBtn:before {
	border-radius: 50%;
	box-shadow: 0 0 0 0.2em #f1f1f1 inset;
	top: 0.75em;
	left: 0.75em;
	width: 1.2em;
	height: 1.2em;
  background: transparent;

}
.searchBtn:after {
	background: #f1f1f1;
	border-radius: 0 0.25em 0.25em 0;
	top: 51%;
	left: 51%;
	width: 0.75em;
	height: 0.25em;
	transform: translate(0.2em,0) rotate(45deg);
	transform-origin: 0 50%;
  /* background: transparent; */

}
.searchBtn span {
	display: inline-block;
	overflow: hidden;
	width: 1px;
	height: 1px;
}
