.tagContainer{
  border-radius: 20px;
   width: max-content;
  word-wrap: unset;
  word-break: keep-all;
  padding: 12px 10px;
  margin: 5px;
  font-weight: normal;
  font-size: 12px;
  line-height: 8px;


  letter-spacing: 0px;
  /* color: var(--tag-list-border-col); */
/*   
  align-items: flex-end;
  align-self: flex-end;
  
  align-content: flex-end; */

  border: solid 2px transparent;
  background-color: transparent;
  background-color: rgba(0, 100, 255, 0.05);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

}


.tagContainer.selected{

  background-color: rgba(0, 100, 255, 0.2);
  /* font-weight: bold; */

}

.tagText{
  text-transform: lowercase; 
  letter-spacing: -1px;
}