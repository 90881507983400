:root{
    --authorContainer-height: 42px;
    --authorContainer-width: 84px;
}



.feedItemContainerOuter{
    cursor: 'grab';
}



.feedItem{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 15px;
    pointer-events: auto;
    cursor: pointer;
    -webkit-box-align: stretch;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    align-items: stretch;
    
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    
    flex-direction: column;
    
    position: relative;
    z-index: 0;
    -webkit-box-flex: 1;
    flex-grow: 0;
    flex-shrink: 0;
    outline-style: none;
    cursor: grab;

    margin-bottom: 5px;

    /* padding-right: 30px; */
    padding-left: 20px;

    max-width: var(--content-feed-max-width);

    padding: 0;
    margin: 0;
  
    word-wrap: break-word;
}



.feedItemInner{
    min-height: 100%;
    height: 100%;
    padding: 24px 60px 14px 20px;
    background-color: var(--feedItemInner-bg);

    min-width: 80%;
    width: 80%;
    max-width: 80%;
    /* max-width: var(--content-feed-max-width);
    max-width: var(--feedItemInner-max-width); */
    line-height: 22px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    overflow: hidden;
}


.feedItemFiller{
    height: 0; 
    overflow: hidden;
}



.feedItem.quoteEntry{
    background-color: var(--quote-entry-bg);
    background-color: var(--header-bg);
    min-height: 150px;
    min-width: 100%;
    width: 100%;
    display: flex;
    outline: none;
}

.feedItem.quoteEntry .quoteSpansOuter {
    /* background-color: black; */
    outline: none;
}




.feedItem.quoteEntry .authorInner.isAnimated span{
    opacity: 1;
    animation: fade 14s infinite ease-in-out;
}



@keyframes fade {

  0%,48%,52%,100% { opacity: 1 }

  50% { opacity: 0.2 }
}



.ratingsContainer{
    height: 40px;
    
}



.authorContainer{
    overflow: visible;
    top: 5px;
    left: 0px;
    /* left: 0.25em; */
    /* left: -20px; */

    position: absolute;
    display: flex;
    align-items: center;
    align-content: center;

    flex-direction: row;
    height: var(--authorContainer-height);
    width: var(--authorContainer-width);
}



.authorContainer .chamferedBox{
    position: absolute;
    width: var(--authorContainer-width);
    height: var(--authorContainer-height);
    top: -8px;
    left: 10px;
}



.authorInner{
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    margin-bottom: 3px;
}



.feedItem .ribbonContainer.yoursRibbon span{
    text-transform: none;
    padding-bottom: 4px;

    margin-top: 0px;
    margin-left: 2px;
    font-size: 12px;
    line-height: 22px;
}




.authorInner span{
    font-size: 9px;
    line-height: 10px;
    font-weight: bold;

    color: #fff;
    text-align: center;
    position: relative;

    white-space: pre-wrap;
    width: min-intrinsic;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    height: auto;
    display: table-caption;
    display: -ms-grid;
    -ms-grid-columns: min-content;
    cursor: pointer;
}



.theQuote{
    box-sizing: border-box;
    background-color:var(--theQuote-bg);
    display: block;
    padding: 10px 15px;
    padding-right: 8px;
    margin: 0;
    /* margin-right: 80px; */
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    cursor: pointer;
    /* border-radius: 15px; */
}

.authorContainer .chamferedBox{
    position: absolute;
    width: var(--authorContainer-width);
    height: var(--authorContainer-height);
    top: 0px;
    left: 0;
}




.feedItem.quoteEntry .theQuote{
    background-color: #fff;
    min-height: 80px;
    min-width: 100%;
    width: 100%;
}

.feedItem.quoteEntry span.quoteSpansOuter{
    min-height: 70px;
   
    user-select: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    min-width: 100%;
    width: 100%;
}



.feedItem.quoteEntry .theQuote .invertedComma{
    /* display: none; */
    color: transparent;
}




.ratingsAndTagsOuter{
    display: flex;
    flex-direction: column;
        
    /* max-width: var(--content-feed-max-width); */

    overflow: visible;
    padding: 0;
    margin: 0;

    position: relative;
    top: 0px;
    right: 0px;
    height: auto;
}



.invertedComma{
    font-family:'Segoe UI','Roboto';
    font-size: 16px;
    letter-spacing: 3px;
    font-weight: bold;
}


.quoteTagsContainer{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 2px;

    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
    width: 100%;
    box-sizing: border-box;
    min-height: 20px;
    max-width: 100%;
}


.essenceBtnOuter{
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    right: 15px;
    top: 25px;
}

.essenceBtnOuter button.toolBtn{
   
    width: 100%;
    height: 35px;
    font-size: 14px;
    background-color: black;
    color: #fff;
    color: var(--nav-selected-bg);

}

.hidden {
    display: none;
}