:root{
  --shelf-bg: rgba(154,84,48);
  --shelf-bg-light: rgba(154,84,48,0.6);

}

.toolBtnOuter .toolBtn {
  width: 28px;
  font-weight: normal;
  border-radius: 3px;
  cursor: pointer;
  height: 24px;
  position: relative;
  border: solid 2px #999;
  border: solid 2px burlywood;
  border: solid 2px #bb9c76;
  border: solid 2px var(--feed-bg);
  border: solid 2px transparent;

  user-select: none;

  color: burlywood;
}

.toolBtnOuter .toolBtn.active:hover{
  border: solid 2px var(--nav-selected-bg);
}

.toolBtnOuter .toolBtn.active, 
.toolBtnOuter .toolBtn:hover {

  color:  var(--nav-selected-bg);
  font-weight: bold;
}

