:root{
  --nav-bg: rgba(214, 231, 204,1);
  --navContainer-height: 55px;
}

#navContainer {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  align-self: center;

  position: fixed;
  width: 100%;
  min-width: 100%;
  flex-direction: column;
  margin-top: var(--top-header-height);
  /* margin-top: 0; */
  height: var(--navContainer-height);
  z-index: 5;
  background-color: var(--nav-bg);
  max-width: var(--max-app-width);

} 



#contentFeedHeaderMenuNav {
  display: flex;

  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  pointer-events: auto;

  box-sizing: border-box;

  flex-basis: auto;
  
  position: relative;
  z-index: 0;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  /* flex-direction: row; */
  -webkit-box-align: center;

  border: 0;
  /* background-color: #cdccdc; */
  /* background-color: var(--nav-bg); */

  align-items: center;
  align-content: center;
  max-width: var(--max-app-width);
  /* max-width: var(--content-feed-max-width); */

  width: 100%;

}

#headerMenuNavItems {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  pointer-events: auto;
  -webkit-box-align: stretch;
  align-items: stretch;

  display: flex;
  min-width: 100%;
  width: 100%;
  max-width: var(--content-feed-max-width);

  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  
  position: relative;
  z-index: 0;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  -webkit-box-flex: 1;
  flex-grow: 1;
  height: 100%;
  flex-wrap: nowrap;
  transform: translate3d(0px, 0px, 0px);
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-padding: 0px 36px;
}