:root{

}


#theHeaderContainer {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  pointer-events: auto;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  z-index: 3;
  backface-visibility: hidden;
  align-self: center;
  align-content: center;
  align-items: center;

  position: fixed;
  margin-left: auto;
  margin-right: auto;
  height: var(--header-container-height);
  width: 100%;
  min-width: 100%;
  background-color: var(--header-bg);

  z-index: 100;
}

#topHeaderContainerOuter {
  position: fixed;
  /* width:var(--app-width); */
  /* min-width: 100%; */
  align-self: center;
  align-content: center;
  z-index: 1000;
  max-width: var(--max-app-width);
  width: 100%;
}


#topHeaderContainer {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 15px;
  -webkit-box-align: center;
  align-items: center;

  box-sizing: border-box;
  display: flex;
  flex-basis: auto;

  margin: 0;
  align-self: center;
  align-content: center;
  pointer-events: auto;
  z-index: 0;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  flex-direction: row;

  height: var(--top-header-height);
  width: 100%;
  max-width: var(--max-app-width);
  min-width: 100%;


  padding: 0;

  z-index: 100;

}

#topHeaderLogo{
  font-family: 'Montserrat Alternates', 'Montserrat', sans-serif;
  font-family: 'Oswald', 'Montserrat', sans-serif;

  display: block;
  font-size: 19px;
  /* color: rgb(74, 44, 0); */
  color: #000;
  color:#fff;
  background-color: var(--essence-hl);
  font-weight: 400;

  /* color: var(--logo-shadow); */
  letter-spacing: 0px;
  margin-left: 5px;
  padding-top: 0px;
  line-height: 18px;
  padding: 5px 5px;

  cursor: default;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  
}

#topHeaderLogo span.beta{
  font-family:Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  background-color: var(--nav-selected-bg);
  background-color: black;
  color: black;
  color: #fff;
  padding: 0px 2px;
  line-height: 12px;
  top: 2px;
  margin-left: 0px;
  /* text-transform: uppercase; */
  border-radius: 3px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

}

