div.profileContainer{
  display: flex;
  right: 5px;
  position: absolute;
  font-size: 14px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  
}

.profileContainerInner{
  color: #fff;

}

.profileContainerInner button{
  border: #fff solid 2px;
  border: none;
  background-color: rgba(85,85,85,0.5);
  background: transparent;
  color: #fff;
  padding: 6px 10px;
  border-radius: 8px;

  
}