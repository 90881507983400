
.quoteSpan{
  cursor: text;

}

.quoteSpan.startSpan{
  padding: 4px 0px;
  line-height: 22px;

}

span.invertedComma{
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.essenceSpan{
  color: #fff;
  /* font-weight: bold; */
  background-color: var(--essence-hl);
  padding: 4px 4px;
}

.highlightThis{
  background-color: var(--nav-selected-bg);
  color: black;
  padding: 4px 2px;


}
